
import { Options, Vue } from "vue-class-component";
import RunnerListGrid from "@/components/User/RunnerListGrid.vue";

@Options({
  components: {
    RunnerListGrid
  }
})
export default class Runners extends Vue {
}

