
import { GameStore } from "@/store/games";
import { Game, StaticContentService, UserList, UserListEntry } from "@/api";
import { Options, Vue } from "vue-class-component";
import { ContentLoader } from "vue-content-loader";
import CountryFlag from "vue-country-flag-next";

@Options({
    components: {
        ContentLoader,
        CountryFlag
    }
})
export default class RunnerListGrid extends Vue {
    runnerListClean: UserList | null = null;
    runnerList: UserListEntry[] | null = null;
    games: Game[] = [];
    loadedFull = false;

    filters: {[stub: string]: number} = {};
    search: string | null = null;
    sortPointDirection = 0;

    readonly FilterIgnore = 0;
    readonly FilterExclude = -1;
    readonly FilterRequire = 1;

    async mounted() {
        let userListPromise = StaticContentService.userListTop100();
        let allGames = (await GameStore.get()).games;
        this.games = allGames.filter(g => !g.Hidden && (g.RunnableSegments?.filter(r => r.Points && r.Points > 0)?.length || 0) > 0)
        this.filters = this.games.reduce((a, g) => { a[g.Id!] = this.FilterIgnore; return a;}, {} as {[stub: string]: number})

        this.runnerListClean = await userListPromise;
        this.processList();
    }

    async loadFullList() {
        if(this.loadedFull) return;

        this.loadedFull = true;
        this.runnerListClean = await StaticContentService.userList();
        this.processList();
    }

    async filter(game: Game) {
        await this.loadFullList();

        if(game.Id == null) return;

        if (game.Id in this.filters) {
            let current = this.filters[game.Id];

            if(current == this.FilterIgnore) {
                current = this.FilterRequire;
            } else if(current == this.FilterRequire) {
                current = this.FilterExclude;
            } else {
                current = this.FilterIgnore;
            }

            this.filters[game.Id] = current;
        } else {
            this.filters[game.Id] = this.FilterExclude;
        }

        this.processList();
    }

    async sortPoints() {
        await this.loadFullList();

        if(this.sortPointDirection == 0) {
            this.sortPointDirection = 1;
        } else if(this.sortPointDirection == 1) {
            this.sortPointDirection = -1;
        } else {
            this.sortPointDirection = 0;
        }

        this.processList();
    }

    searchTimeout : number | null = null;
    async applySearch() {
        await this.loadFullList();

        if(this.searchTimeout != null) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(() => 
        {
            this.searchTimeout = null;
            this.processList();
        }, 750);
    }

    processList() {
        if(this.runnerListClean?.Entries == null)
            return;

        this.runnerList = this.runnerListClean.Entries;

        var searchPattern = new RegExp(".*" + this.search + ".*", "i");

        var index = 0;
        for(var entry of this.runnerList) {
            (entry as any).rank = ++index;
            (entry as any).show = true;

            let exclude = false;

            if(this.search) {
                if(!searchPattern.test(entry.Username!))
                    exclude = true;
            }

            for(var filt in this.filters)
            {
                var value = this.filters[filt];
                var filtered = entry.SubmittedGames?.indexOf(filt, 0) ?? -1;

                if(value == this.FilterRequire) {
                    if(filtered == -1)
                        exclude = true;
                } else if (value == this.FilterExclude) {
                    if(filtered >= 0)
                        exclude = true;
                }
            }

            if(exclude)
            {
                (entry as any).show = false;
            }
        }

        if(this.sortPointDirection != 0) {
            this.runnerList = this.runnerList.sort((a,b) => a.Points! - (this.sortPointDirection * b.Points!));
        }
    }
}

